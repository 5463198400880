import { Box } from "grommet";
import Icon from "@components/dynamicIcon";
import Image from "next/image";
import { useInView } from "react-intersection-observer";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import discordSvg from "public/assets/images/discord.svg";
import twitterSvg from "public/assets/footer/twitter.svg";
import cmcSvg from "public/assets/footer/cmc.svg";
import { CircleExternalLink } from "./anchorLink";

const options = {
  rootMargin: "200px 0px",
  triggerOnce: true,
};

function FirstRow({ showSvg, ...props }) {
  return (
    <Box direction="row" {...props}>
      <CircleExternalLink
        href="https://twitter.com/@coinscopecrypto"
        icon={
          showSvg && (
            <Image alt="Twitter" width={24} height={24} src={twitterSvg} />
          )
        }
        size="xsmall"
        title="Twitter"
        data-ga="footer::click::twitter"
        backgroundColor="white"
      />
      <CircleExternalLink
        href="https://www.facebook.com/Coinscopecrypto"
        icon={
          showSvg && (
            <Icon svg={() => import("grommet-icons/icons/FacebookOption")} />
          )
        }
        size="xsmall"
        title="Facebook"
        data-ga="footer::click::facebook"
        backgroundColor="neutral-3"
      />
      <CircleExternalLink
        href="https://coinmarketcap.com/community/profile/Coinscope"
        icon={
          showSvg && (
            <Image alt="Coinmarketcap" width={24} height={24} src={cmcSvg} />
          )
        }
        size="xsmall"
        title="Coinmarketcap"
        data-ga="footer::click::coinmarketcap"
        backgroundColor="white"
      />
    </Box>
  );
}

function SecondRow({ showSvg, ...props }) {
  return (
    <Box direction="row" {...props}>
      <CircleExternalLink
        href="https://www.reddit.com/r/Coinscope"
        icon={
          showSvg && <Icon svg={() => import("grommet-icons/icons/Reddit")} />
        }
        size="xsmall"
        title="Reddit"
        data-ga="footer::click::reddit"
        backgroundColor="status-warning"
      />
      <CircleExternalLink
        href="https://discord.com/invite/fGxEQxK29N"
        icon={
          showSvg && (
            <Image alt="Discord" width={24} height={24} src={discordSvg} />
          )
        }
        size="xsmall"
        title="Discord"
        data-ga="footer::click::discord"
        backgroundColor="#a8adf0"
      />
      <CircleExternalLink
        href="https://medium.com/@coinscopecrypto"
        icon={
          showSvg && (
            <Icon
              color="dark-1"
              svg={() => import("grommet-icons/icons/Medium")}
            />
          )
        }
        size="xsmall"
        title="Medium"
        data-ga="footer::click::medium"
        backgroundColor="neutral-1"
      />
    </Box>
  );
}

function ThirdRow({ showSvg, ...props }) {
  return (
    <Box direction="row" {...props}>
      <CircleExternalLink
        href="https://www.instagram.com/coinscopecrypto/"
        icon={
          showSvg && (
            <Icon
              color="accent-4"
              svg={() => import("grommet-icons/icons/Instagram")}
            />
          )
        }
        size="xsmall"
        title="Instagram"
        data-ga="footer::click::instagram"
        backgroundColor="#E1306C"
      />
      <CircleExternalLink
        href="https://t.me/coinscope_co"
        icon={
          showSvg && (
            <Icon
              color="plain"
              svg={() => import("grommet-icons/icons/Send")}
            />
          )
        }
        size="xsmall"
        title="Join our Telegram group"
        data-ga="footer::click::telegram"
        backgroundColor="light-1"
      />
      <CircleExternalLink
        href="https://www.youtube.com/@coinscope7963"
        icon={
          showSvg && (
            <Icon
              size="medium"
              svg={() => import("grommet-icons/icons/Youtube")}
            />
          )
        }
        size="xsmall"
        title="Youtube"
        data-ga="footer::click::youtube"
        backgroundColor="#FF0000"
      />
    </Box>
  );
}

export default function SocialLinks() {
  const large = useIsLarge();
  const { ref, inView } = useInView(options);
  const showSvg = inView || null;
  const gap = large ? "medium" : "xlarge";

  return (
    <Box align="center" gap={gap} ref={ref}>
      <FirstRow showSvg={showSvg} gap={gap} />
      <SecondRow showSvg={showSvg} gap={gap} />
      <ThirdRow showSvg={showSvg} gap={gap} />
    </Box>
  );
}
