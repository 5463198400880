import dynamic from "next/dynamic";
import { useState } from "react";
import { useTimeout } from "usehooks-ts";

const Container = dynamic(() => import("./container"));

const CashbackNotification = () => {
  const [show, setShow] = useState(false);

  useTimeout(() => setShow(true), 5000);

  return show ? <Container /> : null;
};

export default CashbackNotification;
