import Link from "next/link";
import styled from "styled-components";
import { Anchor, Box } from "grommet";

const Circle = styled(Box)`
  transition: 0.6s;

  &:hover {
    transform: scale(1.1);
    color: #f8f8f8;
  }
`;

export const NakedLink = styled(Anchor).attrs(({ label, title }) => ({
  title: title || `Go to ${label}`,
}))`
  padding: 12px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;

  &:hover {
    color: #43cbdb;
  }

  &:active {
    color: #43cbdb;
  }

  &&& {
    span {
      display: flex !important;
    }
  }
`;

const CenterExternalLink = styled(ExternalLink)`
  margin-bottom: -4px;
`;

export default function AnchorLink({ href, ...rest }) {
  return (
    <Link href={href} passHref legacyBehavior>
      <NakedLink {...rest} />
    </Link>
  );
}

export function ExternalLink({ ...props }) {
  return <AnchorLink target="_blank" rel="noopener" {...props} />;
}

export function CircleExternalLink({ backgroundColor, ...props }) {
  return (
    <Circle
      round="full"
      pad="2px"
      justify="center"
      align="center"
      background={backgroundColor}
    >
      <CenterExternalLink {...props} />
    </Circle>
  );
}
