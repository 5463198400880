import { useEffect } from "react";
import dynamic from "next/dynamic";
import useAuth from "@organisms/auth/useAuth";
import useHash from "@hooks/useHash";

const SearchOverlay = dynamic(() => import("./search"));
const MenuOverlay = dynamic(() => import("./menu"));
const LoginOverlay = dynamic(() => import("./login"));
const StakingOverLay = dynamic(() => import("./stake"));
const WalletOverLay = dynamic(() => import("./wallet"));
const ProviderOverLay = dynamic(() => import("./providers"));

export default function Overlays(props) {
  const auth = useAuth();
  const [hash, setHash, setSilentHash] = useHash();

  const isLogin = hash.indexOf("login") === 0;

  useEffect(() => {
    if (auth.user && isLogin) setSilentHash("");
  }, [auth]);

  const onClose = () => setHash("");

  if (hash === "search") return <SearchOverlay {...props} onClose={onClose} />;

  if (hash === "menu") return <MenuOverlay {...props} onClose={onClose} />;

  if (hash.indexOf("wallet") === 0)
    return <WalletOverLay {...props} onClose={onClose} />;

  if (hash.indexOf("providers") === 0)
    return <ProviderOverLay {...props} onClose={onClose} />;

  if (hash.indexOf("earn") === 0) {
    const match = hash.match(/earn&(.*)$/);
    const type = match && match[1];
    return <StakingOverLay {...props} type={type} onClose={onClose} />;
  }

  if (isLogin && !auth.user) {
    const match = hash.match(/login&(.*)$/);
    const type = match && match[1];
    return <LoginOverlay {...props} type={type} onClose={onClose} />;
  }

  return null;
}
