import { Box } from "grommet";
import Image from "next/legacy/image";
import { useInView } from "react-intersection-observer";

const options = {
  rootMargin: "100px 0px",
  triggerOnce: true,
};

export default function Android() {
  const { ref, inView } = useInView(options);

  return (
    <Box pad={{ top: "medium" }}>
      <a
        href="https://play.google.com/store/apps/details?id=co.coinscope.www.twa"
        target="_blank"
        rel="noopener"
        ref={ref}
        data-ga="footer::click::android"
      >
        {inView && (
          <Image
            width={200}
            height={77}
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        )}
      </a>
    </Box>
  );
}
