import { Blank } from "grommet-icons";
import styled from "styled-components";
import dynamic from "next/dynamic";
import { useMemo } from "react";

const Skeleton = styled(Blank)`
  background: #999;
  border-radius: 8px;
`;

const SkeletonWrapper = () => <Skeleton />;

export default function Icon({ svg, ...rest }) {
  const SVG = useMemo(
    () =>
      dynamic(
        () =>
          svg().then((mod) => {
            const [, key] = Object.keys(mod);
            return mod[key];
          }),
        {
          loading: SkeletonWrapper,
          ssr: false,
        }
      ),
    []
  );

  return <SVG {...rest} />;
}
