import { Box, Text } from "grommet";
import { FormNextLink } from "grommet-icons";
import styled from "styled-components";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";

const Container = styled(Box)`
  font-weight: initial;
  display: flex;
  text-decoration: initial;
  color: white;

  &:hover {
    color: ${({ theme }) => theme.global.colors.brand};
  }
`;

function New({ href, children, ...rest }) {
  const large = useIsLarge();
  return (
    <Container
      tag="a"
      direction="row"
      pad={large ? "xsmall" : "none"}
      round="small"
      gap="xsmall"
      align="center"
      href={href}
      target="_blank"
      rel="noopener"
      flex={false}
      responsive={false}
      {...rest}
    >
      <Text size={large ? "small" : "xsmall"}>{children}</Text>
      <FormNextLink />
    </Container>
  );
}

export default function UpcomingNews({ notifications }) {
  const large = useIsLarge();

  return (
    <Box
      direction="row"
      pad="xsmall"
      gap="medium"
      overflow={{ horizontal: "auto" }}
      margin={{ top: large ? "none" : "small" }}
      justify={large ? "center" : "start"}
    >
      {notifications.map(({ id, title, url }) => (
        <New key={id} href={url} alt={title}>
          {title}
        </New>
      ))}
    </Box>
  );
}
