import { Box, Main } from "grommet";
import Header from "@components/header";
import Footer from "@components/footer";
import Seo from "@components/seo";
import Overlays from "@components/overlays";
import styled from "styled-components";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import { usePageValue } from "@organisms/page/provider";
import { Fragment } from "react";
import UpcomingNews from "components/header/upcomingNews";
import CashbackNotification from "@components/cashbackNotification";
import CoinProvider from "@organisms/coin/provider";

const ContentWrapper = styled(Main).attrs((props) => ({
  flex: true,
  width: { width: "100%", max: "1600px" },
  alignSelf: "center",
  direction: "row",
  overflow: "initial",
  ...props,
}))`
  padding: ${({ desktopPadding = "0 24px 0 0" }) => desktopPadding};
  margin: ${({ desktopMargin = "0 0 96px 0" }) => desktopMargin};
  @media (max-width: 1280px) {
    /* margin: 0 0 96px; */
    padding: ${({ mobilePadding = "0 12px" }) => mobilePadding};
  }
`;

function ContainerWrapper({ customBackground, ...rest }) {
  return customBackground ? (
    <Box background={customBackground} {...rest} />
  ) : (
    <Fragment {...rest} />
  );
}

const emptyObject = {};

export default function Layout({
  seo,
  children,
  max,
  pad = true,
  customBackground,
  containerProps,
  disableCashbackPopup,
  coins = emptyObject,
  ...rest
}) {
  const large = useIsLarge();
  const notifications = usePageValue("notifications");

  return (
    <CoinProvider value={coins}>
      <Box height={{ min: "100vh" }} background="#1B2130">
        <ContainerWrapper customBackground={customBackground}>
          <Seo {...seo} />
          <Header />
          {notifications && notifications.length > 0 && (
            <UpcomingNews notifications={notifications} />
          )}
          {!disableCashbackPopup && <CashbackNotification />}
          <ContentWrapper {...containerProps}>
            <Box
              {...(large && pad && { pad: { horizontal: "medium" } })}
              width={{ width: "100%", ...(max && { max }) }}
              margin={{ top: "medium", horizontal: "auto" }}
              {...rest}
            >
              {children}
            </Box>
          </ContentWrapper>
          <Footer />
          <Overlays />
        </ContainerWrapper>
      </Box>
    </CoinProvider>
  );
}
