import dynamic from "next/dynamic";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";

const Desktop = dynamic(() => import("./desktop"));
const Mobile = dynamic(() => import("./mobile"));

export default function Header(props) {
  const large = useIsLarge();

  return large ? <Desktop {...props} /> : <Mobile {...props} />;
}
