import { Footer as GFooter, Box } from "grommet";
import { forwardRef } from "react";
import dynamic from "next/dynamic";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import Newsletter from "@components/newsletter";
import Logo from "@components/header/logo";
import { usePageValue } from "@organisms/page/provider";
import AnchorLink from "./anchorLink";
import SocialLinks from "./socialLinks";
import Android from "./android";

const options = {
  threshold: 0.5,
  triggerOnce: true,
};

const BotFooter = dynamic(() =>
  import("@components/seo/botHeader").then((component) => component.Footer)
);
const FooterVectorDesktop = dynamic(() => import("./footerVectorDesktop"), {
  ssr: false,
});
const FooterVectorMobile = dynamic(() => import("./footerVectorMobile"), {
  ssr: false,
});

const Column = styled(Box)`
  ${({ large }) => (large ? `text-align: start;` : ` text-align: center;`)}
`;

function FirstColumn(props) {
  const large = useIsLarge();
  return (
    <Column flex={{ grow: 2 }} large={large} {...props}>
      <AnchorLink
        href="/promote"
        label="Promote"
        data-ga="footer::click::promote"
      />
      <AnchorLink
        href="mailto:contact@coinscope.co"
        size="medium"
        label="Contact us"
        target="_blank"
        data-ga="footer::click::contactUs"
        rel="noopener"
      />
      <AnchorLink
        href="https://docs.coinscope.co/"
        label="Documentation"
        target="_blank"
        data-ga="footer::click::documentation"
        rel="noopener"
      />
      <AnchorLink
        href="/token"
        label="$Coinscope Coin"
        data-ga="footer::click::token"
      />
      <AnchorLink
        href="https://www.cyberscope.io/"
        label="Cyberscope"
        target="_blank"
        rel="noopener"
        data-ga="footer::click::cyberscope"
      />
      <AnchorLink
        href="/badges"
        label="Badges"
        data-ga="footer::click::badges"
      />

      <AnchorLink href="/blog" label="Blog" data-ga="footer::click::blog" />
      <AnchorLink href="/terms" label="Terms" data-ga="footer::click::terms" />
      <AnchorLink
        href="/privacy"
        label="Privacy"
        data-ga="footer::click::privacy"
      />
      <AnchorLink
        href="/about"
        label="About us"
        data-ga="footer::click::aboutUs"
      />
      <AnchorLink
        href="/careers"
        label="Careers"
        data-ga="footer::click::careers"
      />
    </Column>
  );
}

function SecondColumn({ ...props }) {
  const large = useIsLarge();
  return (
    <Column flex={{ grow: 2 }} large={large} {...props}>
      <AnchorLink
        href="https://www.cyberscope.io/cyberscan"
        label="Cyberscan™"
        data-ga="footer::click::cyberscan"
        target="_blank"
      />
      <AnchorLink
        href="https://www.cyberscope.io/safescan"
        label="Safescan™"
        data-ga="footer::click::safescan"
        target="_blank"
      />
      <AnchorLink
        href="https://www.cyberscope.io/similarityscan"
        label="Similarityscan™"
        data-ga="footer::click::similarityscan™"
        target="_blank"
      />
      <AnchorLink
        href="/airdrop"
        label="Airdrop"
        data-ga="footer::click::airdrop"
      />
      <AnchorLink href="/audit" label="Audit" data-ga="footer::click::audit" />
      <AnchorLink href="/kyc" label="KYC" data-ga="footer::click::kyc" />
      <AnchorLink
        href="/escrow"
        label="Escrow"
        data-ga="footer::click::escrow"
      />
      <AnchorLink href="/ama" label="AMA" data-ga="footer::click::ama" />
      <AnchorLink href="/earn" label="Earn" data-ga="footer::click::earn" />
      <AnchorLink href="/nft" label="NFT" data-ga="footer::click::nft" />
      <AnchorLink
        href="/createtoken"
        label="Create Token"
        data-ga="footer::click::createtoken"
      />
      <AnchorLink
        href="/lockers/tokens"
        label="Lockers"
        data-ga="footer::click::lockers"
      />
      <AnchorLink
        href="/watchlist"
        label="Watchlist"
        data-ga="footer::click::watchlist"
      />
      <AnchorLink
        href="/portfolio"
        label="Portfolio"
        data-ga="footer::click::portfolio"
      />
    </Column>
  );
}

// eslint-disable-next-line react/display-name
const ThirdColumn = forwardRef((props, ref) => {
  return (
    <Column align="center" flex={{ grow: 3 }} ref={ref} {...props}>
      <SocialLinks />
      <Android />
    </Column>
  );
});

function Container({ children, ...rest }) {
  return (
    <GFooter background="#141823" direction="column" {...rest}>
      {children}
    </GFooter>
  );
}

function Desktop(props) {
  const { ref, inView } = useInView(options);
  const bot = usePageValue("bot");

  return (
    <Container {...props}>
      <Box background="#F9F9F9" border={{ side: "top" }} fill>
        <Newsletter
          alignSelf="center"
          width={{ width: "100%", max: "xlarge" }}
          pad={{ top: "medium", horizontal: "large" }}
        />
      </Box>
      <Box
        direction="column"
        justify="start"
        align="start"
        pad={{ horizontal: "medium", top: "large" }}
        width={{ width: "100%", max: "xxlarge" }}
        ref={ref}
      >
        {bot && <BotFooter />}
        <Logo />
        <Box
          direction="row"
          justify="between"
          align="center"
          pad={{ horizontal: "4.1rem", top: "xlarge" }}
          fill
        >
          <Box direction="row" justify="start" flex={{ grow: 1 }}>
            <FirstColumn {...props} />
            <SecondColumn {...props} />
          </Box>
          <Box background="#2E364D" width="1px" alignSelf="stretch" />
          <Box
            direction="row"
            justify="evenly"
            align="center"
            flex={{ grow: 1 }}
          >
            <ThirdColumn gap={"medium"} />
          </Box>
        </Box>
      </Box>
      <Box height={{ min: "255px" }} width="100%">
        {inView && <FooterVectorDesktop />}
      </Box>
    </Container>
  );
}

function Mobile(props) {
  const { ref, inView } = useInView(options);
  const bot = usePageValue("bot");

  return (
    <Container {...props}>
      <Box background="#F9F9F9" border={{ side: "top" }} fill>
        <Newsletter pad={{ horizontal: "medium" }} />
      </Box>
      {bot && <BotFooter />}
      <Box pad={{ top: "xlarge" }}>
        <Logo />
      </Box>
      <Box direction="row" pad={{ top: "large", bottom: "xlarge" }} fill>
        <FirstColumn flex {...props} />
        <SecondColumn flex {...props} />
      </Box>
      <Box
        background="#2E364D"
        width={{ width: "100%", max: "full" }}
        height="1px"
      />
      <ThirdColumn ref={ref} gap={"xlarge"} pad={{ top: "xlarge" }} />
      <Box height={{ min: "235px" }} width="100%">
        {inView && <FooterVectorMobile />}
      </Box>
    </Container>
  );
}

export default function Footer() {
  const large = useIsLarge();
  return large ? <Desktop /> : <Mobile />;
}
