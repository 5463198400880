import { useMemo } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

export const seoDescription =
  "Discover the best new cryptocurrency projects! Find what is launching soon and vote for your favorite coin.";
const seoImageSrc = "https://www.coinscope.co/assets/images/cover_resized.jpg";

const getMetaTitle = (title) => (title ? `Coinscope | ${title}` : `Coinscope`);
const getMetaDescription = (str) =>
  str > 150 ? `${str.substring(0, 155)}...` : str;
const getCanonicalUrl = (url) => `https://www.coinscope.co${url}`;

const Seo = ({
  title,
  description = seoDescription,
  image = seoImageSrc,
  type = "website",
}) => {
  const { asPath } = useRouter();

  const getTitle = useMemo(() => getMetaTitle(title), [title]);
  const getDescription = useMemo(
    () => getMetaDescription(description),
    [description]
  );
  const getCanonical = useMemo(() => getCanonicalUrl(asPath), [asPath]);

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <title>{getTitle}</title>
      <meta name="description" content={getDescription} />
      <meta property="fb:pages" content="113388070993580" />
      <meta property="og:title" content={getTitle} />
      <meta property="og:description" content={getDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={getCanonical} />
      <meta property="og:type" content={type} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Coinscope" />
      <link rel="canonical" href={getCanonical} />

      <meta name="twitter:site" content="@coinscopecrypto" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={getTitle} />
      <meta name="twitter:description" content={getDescription} />
      <meta name="twitter:image" content={image} />

      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
};

export default Seo;
