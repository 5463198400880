import { useEffect } from "react";

function injectScript(id, src, onLoad) {
  if (document.getElementById(id)) return;

  const js = document.createElement("script");
  js.id = id;
  js.async = true;
  js.src = src;
  js.onload = onLoad;

  const fjs = document.getElementsByTagName("script")[0];
  fjs.parentNode.insertBefore(js, fjs);
}

export default function useInjectScript(id, src, { onLoad = undefined } = {}) {
  useEffect(() => {
    if (!id) return;

    injectScript(id, src, onLoad);
  }, [id]);
}
