import useAuth from "@organisms/auth/useAuth";
import makeProvider from "@cybertools/ui/es/helpers/makeProvider";

const mergeCollection = (state, collection) => {
  state = { ...state };

  Object.values(collection).forEach((coin) => {
    state[coin.slug] = {
      ...state[coin.slug],
      ...coin,
      ...((state[coin.slug]?.airdrop || coin?.airdrop) && {
        airdrop: { ...state[coin.slug]?.airdrop, ...coin?.airdrop },
      }),
    };
  });

  return state;
};

const {
  Provider: CoinProvider,
  useCollection,
  useResource,
  useResourceValue,
  useCollectionDispatch,
  useResourceDispatch,
} = makeProvider({ mergeCollection });

export default CoinProvider;

export const useCoins = useCollection;

export const useCoin = useResource;

export const useCoinValue = useResourceValue;

export const useAudit = (slug) => useCoin(slug).audit;

export const useAirdrop = (slug) => useCoin(slug).airdrop;

export const useWatchlisted = (slug) => {
  const auth = useAuth();
  const { watchlisted } = useCoin(slug);

  return auth.user && watchlisted;
};

export const useHasPortfolio = (slug) => {
  const auth = useAuth();
  const { hasPortfolio } = useCoin(slug);

  return auth.user && hasPortfolio;
};

export const useCoinDispatch = useCollectionDispatch;

export const useCoinResourceDispatch = useResourceDispatch;
